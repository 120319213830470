import { io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

// export const socket = io(SOCKET_URL,
//         {
//             extraHeaders: {
//                 'Authorization': `Bearer ${prompt_access_token}`,
//                 'chat_room': localStorage.getItem('prompt_chat_room', null)
//             }
//         })
    
export const get_socket = (access_token) => {
    console.log("Getting socket using :", access_token)
    const socket = io(SOCKET_URL,
        {
            extraHeaders: {
                'Authorization': `Bearer ${access_token}`,
                'chat_room': localStorage.getItem('prompt_chat_room', null)
            }
        })
    return socket
}
