import classes from './Chat.module.scss'
import natasha from '../../assets/imags/natasha.png'

import {TimeOutHandler, encryptData, decryptData, get_new_access_token} from '../../utils/helper_functions'

import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom'

import { ImpulseSpinner } from "react-spinners-kit";



const Chat = (props) => {
    const params = useParams()
    const socket = props.socket
    const ref = useRef(null)
    const prompt_chatroom_key = localStorage.getItem('prompt_chat_room', '')
    const API_URL = process.env.REACT_APP_API_URL
    const aes_salt_key = process.env.REACT_APP_AES_SALT
    const agent_name = params.agent_name

    const prompt_uuid = decryptData(localStorage.getItem('prompt_uuid', null), aes_salt_key)
    const prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)

    const [chats, setChats] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [msg, setMsg] = useState('')
    
    const [accessTokenChanged, setAccessTokenChanged] = useState(false)
    const [insertChats, setInsertChats] = useState(true)
    const [msgSendInProgress, setMsgSendInProgress] = useState(false)
    const [lastDate, setLastDate] = useState('')

    const [isChatDisconnected, setIsChatDisconnected] = useState(false)
    const [isMsgEmpty, setIsMsgEmpty] = useState(true)
    const [msgSentError, setMsgSentError] = useState(false)


    // websocket events and handler functions, useEffect
    const onSignatureErr = async (value) => {
        console.log("SOCKET ERROR :", value)
        if(msgSendInProgress){
            setInsertChats(false) //dont insert chats anymore in chats array
            console.log("token expired when sending")
            await get_new_access_token(prompt_uuid, prompt_access_token, prompt_chatroom_key, API_URL, aes_salt_key)
            setAccessTokenChanged(true)
            // handleMsgSend()
            setMsgSendInProgress(false)
        }else{
            console.log("token expired before socket connected :")
            await get_new_access_token(prompt_uuid, prompt_access_token, prompt_chatroom_key, API_URL, aes_salt_key)
            setAccessTokenChanged(true)
            // socket.emit('reconnect')
        }
    }
    const onError = async (value) => {
        console.log("ERROR - maybe access token, chat room token or uuid deleted :", value)
        await get_new_access_token(prompt_uuid, prompt_access_token, prompt_chatroom_key, API_URL, aes_salt_key)
        setAccessTokenChanged(true)
    }


    // ################################# functions #################################
    function onInitialMessageReceived(value){
        const all_chats = JSON.parse(value)
        setChats(all_chats)
        setIsLoading(false)

        if(all_chats[all_chats.length - 1]['agent_status']=== 'INACTIVE'){
            console.log("WS Disconnected. so disabling input")
            setIsChatDisconnected(true)
        }
    }
    function onInitialMessageReceivedClosed(){
        console.log("Initialization closed")
    }


    function onMessageReceived(value){
        if(!msgSentError){
            setIsLoading(false)
            const sent_msg = JSON.parse(value)
            setChats([...chats, sent_msg])

            if(sent_msg['agent_status'] === 'INACTIVE'){
                console.log("WS Disconnected. so disabling input")
                setIsChatDisconnected(true)
            }
        }
    }
    function onMessageReceivedClosed(){
        console.log("Connection closed")
    }


    useEffect(()=>{
        // console.log("SOCKET IN INITIALIZATION IS :", socket)
        socket.on('initialization', onInitialMessageReceived);
        return () => {
            socket.off('initialization', onInitialMessageReceivedClosed);
        }
    }, [socket])

    useEffect(() => {
        // console.log("Now chats are :", chats)
        socket.on('msg_from_server', onMessageReceived);
        return () => {
            socket.off('msg_from_server', onMessageReceivedClosed);
        }
    }, [socket, chats])




    // scroll to bottom functionality
    const scrollToBottom = () => {
        const lastChildElement = ref.current?.lastElementChild;
        lastChildElement?.scrollIntoView({ behavior: 'smooth' });      
    }
    useEffect(()=>{
        scrollToBottom()
    }, [chats])
   
    
    // type and send msg functionality
    const handleMsgSend = () => {
        setMsgSentError(false)
        if(!isMsgEmpty && msg!=='' && !isLoading){
            var when;
            if(msgSendInProgress){
                when = lastDate
            }else{
                const date = new Date()
                when = date.getHours()+":"+date.getMinutes()
                setLastDate(when)
            }
            console.log("msg send again :", when)

            var msg_to_be_sent = {
                from: prompt_chatroom_key,
                msg: msg,
                when: when,
                status: 'delivered'
            }
            
            setChats([...chats, msg_to_be_sent])
            setMsg('')
            setIsLoading(true)
            socket.timeout(60000).emit('msg_from_user', JSON.stringify(msg_to_be_sent), (err)=>{
                if(err){
                    console.log("timeout err :", err)
                    msg_to_be_sent['status'] = 'sent'
                    setMsgSentError(true)
                    setChats([...chats, msg_to_be_sent])
                    setIsLoading(false)
                }
            })
        }else{
            console.log("Enter msg and try again")
        }
    }



    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleMsgSend()
        }
    };   
    const handleMsgInput = (e) => {
        const input_msg = e.target.value
        setMsg(input_msg)
        if(input_msg===''){
            setIsMsgEmpty(true)
        }else{
            setIsMsgEmpty(false)
        }
    }

    const process_msg = (msg) => {
        if(msg.includes('https://jojo.co.il')){
            console.log("MSG includes url")
            const msg_list = msg.split('https://jojo.co.il')
            return <>
                {msg_list[0]}
                <a className={classes.anchor_tag} href="https://jojo.co.il" rel="noreferrer" target="_blank">https://jojo.co.il</a>
                {msg_list[msg_list.length-1]}
            </>        
        }else{
            return msg
        }
    }


    
    const get_offline_placeholder = () => {
        return <>{`${agent_name.charAt(0).toUpperCase() + agent_name.slice(1)} is offline now. Reach her at:`}<a className={classes.anchor_tag_secondary} href="https://jojo.co.il" rel="noreferrer" target="_blank">https://jojo.co.il</a></> 
    }

    return(
        <div className={classes.chat_div}>
            <div className={classes.chat_div_main}>
                <div className={classes.chat_div_main_left}>
                    <img src={natasha} alt=''/>
                </div>

                {/* chat section */}
                <div className={classes.chat_div_main_right}>
                    <div className={classes.chat_div_chat}>
                        <div className={classes.chat_div_chat_scroll}>
                            <div ref={ref} className={classes.chat_div_chat_scroll_100}>
                                {/* map all chats to div */}
                                {chats.map((item, index) => 
                                <div key={index}>
                                    {item['from'] === 'agent'?
                                        (
                                            <>
                                                <div className={classes.each_chat_div_parent_left}>
                                                    <div className={classes.each_chat_div_left}>
                                                        <div className={classes.each_chat_div_left_profile}>
                                                            <div className={classes.chat_div_chat_icon}>
                                                                <img src={natasha} alt='natasha'/>
                                                            </div>
                                                            <div className={item['agent_status']==='ACTIVE'?classes.active_status: classes.inactive_status}></div>
                                                        </div>
                                                        <div className={classes.chat_div_chat_msg}>
                                                            {process_msg(item['msg'])}
                                                        </div>
                                                        <div className={classes.chat_div_chat_msg_time_status_div}>
                                                            <div className={classes.chat_div_chat_msg_time}>
                                                                {item['when']}
                                                            </div>
                                                            <div className={item['status']==='sent'?classes.chat_div_chat_msg_status_gray:classes.chat_div_chat_msg_status}>
                                                                ✓✓
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ):(
                                            <div className={classes.each_chat_div_parent_right}>
                                                <div className={`${classes.each_chat_div_right}`}>
                                                    <div className={classes.chat_div_chat_msg}>
                                                        {item['msg']}
                                                    </div>
                                                    <div className={classes.chat_div_chat_msg_time_status_div}>
                                                        <div className={classes.chat_div_chat_msg_time}>
                                                            {item['when']}
                                                        </div>
                                                        <div className={item['status']==='sent'?classes.chat_div_chat_msg_status_gray:classes.chat_div_chat_msg_status}>
                                                            ✓✓
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )} 
                                </div>)}

                                {/* typing animation when agent is producing result */}
                                {isLoading && 
                                    <div className={classes.each_chat_div_parent_left}>
                                        <div className={classes.each_chat_div_left}>
                                            <div className={classes.each_chat_div_left_profile}>
                                                <div className={classes.chat_div_chat_icon}>
                                                    <img src={natasha} alt='natasha'/>
                                                </div>
                                                <div className={classes.active_status}></div>
                                            </div>
                                            <div className={classes.text_spinner}>
                                                <ImpulseSpinner size={30} frontColor="#ffffff80"/>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                            {isChatDisconnected?(
                                <div className={classes.input_div_inactive}>
                                    {get_offline_placeholder()}
                                </div>
                            ):(
                                <div className={classes.input_div}>
                                    <input type='text' placeholder={`Send message to ${agent_name}!`} value={msg} onKeyDown={handleKeyDown} onChange={handleMsgInput}/>
                                    <button onClick={handleMsgSend}>{isLoading?<ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>:"SEND"}</button>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chat;











    // console.log("HERE -------------> :", tempMsgObj, tempMsgObj.length)
    //     if(tempMsgObj.length === 1){
    //         tempMsgObj[0]['status'] = 'delivered'
    //         setChats([...chats, tempMsgObj[0], JSON.parse(value)])
    //         setTempMsgObj([])
    //     }else{
    //     }

    // type and send msg functionality
    // const handleMsgSend = () => {
    //     if(!isMsgEmpty && msg!=='' && (!isLoading || msgSendInProgress)){
    //         console.log("msg send again :", insertChats, msg)

    //         var when;
    //         if(msgSendInProgress){
    //             when = lastDate
    //         }else{
    //             const date = new Date()
    //             when = date.getHours()+":"+date.getMinutes()
    //             setLastDate(when)
    //         }

    //         var msg_to_be_sent = {
    //             from: prompt_chatroom_key,
    //             msg: msg,
    //             when: when,
    //         }

    //         setMsg('')
    //         setMsgSendInProgress(true)
    //         socket.timeout(30000).emit('msg_from_user', JSON.stringify(msg_to_be_sent), (response, err)=>{
    //             if(err){
    //                 console.log("timeout err :", err)
    //                 setMsgSendInProgress(false)
    //                 msg_to_be_sent['status'] = 'sent'
    //             }else{
    //                 msg_to_be_sent['status'] = 'delivered'
    //                 setIsLoading(true)
    //             }
    //             if(insertChats){
    //                 console.log("inserting chat :")
    //                 setChats([...chats, msg_to_be_sent])
    //             }
    //         })
    //     }else{
    //         console.log("Enter msg and try again")
    //     }
    // }
