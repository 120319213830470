import classes from './Main.module.scss'

// pages
import Chat from '../Chat/Chat'

// components
import NavBar from "../../components/NavBar/NavBar"
import NavBarMobile from "../../components/NavBarMobile/NavBarMobile"


// moduels/packages/libraries
import { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom'


// local modules
import useWindowSize from '../../assets/hooks/useWindowSize.js'
import { useSelector } from "react-redux"

import { GooSpinner } from "react-spinners-kit";

import {TimeOutHandler, encryptData, decryptData, get_new_access_token} from '../../utils/helper_functions'
import {get_socket} from '../../utils/socket'




const Main = () => {
    const params = useParams()
    const navigate = useNavigate()
    const given_chatroom_key = params.chatroom_key

    const API_URL = process.env.REACT_APP_API_URL
    const aes_salt_key = process.env.REACT_APP_AES_SALT
    let {width} = useWindowSize()
    const showNavBarMobile = useSelector(state => state.ui.showNavBarMobile)

    const temp_uuid = localStorage.getItem('prompt_uuid', null)
    const prompt_uuid = decryptData(temp_uuid, aes_salt_key)

    const prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)
    const prompt_chatroom_key =  localStorage.getItem('prompt_chat_room', '')
    
    // states
    const [showLoading, setShowLoading] = useState(true)
    const [tryAgain, setTryAgain] = useState(true)

    const [socket, setSocket] = useState('')


    // ######################## functions to work with auth, chatroom_token and access_token ########################
    // create annonymous user
    const create_ann_user = async () => {
        console.log("Authenticating user :", API_URL, aes_salt_key)
        const res = await fetch(`${API_URL}/api/v1/auth`, {
            method: 'GET',
        })
        const res_status = res.status
        const data = await res.json()
        console.log('auth data :', data)
        if(res_status === 200) {
            localStorage.setItem('prompt_uuid', encryptData(data['uuid'], aes_salt_key))
            localStorage.setItem('prompt_access_token', encryptData(data['access_token'], aes_salt_key))
            localStorage.setItem('prompt_chat_room', data['chat_token'])
            setSocket(get_socket(data['access_token']))
            navigate(`/agent/natasha/${data['chat_token']}`)
            setShowLoading(false)
        }else{
            console.log("Creating anonymous user failed, reload the website:", res_status, data)
        }
    }


    // get chatroom key for anonymous user
    const get_chatroom_key = async (uuid, access_token) => {
        console.log("Get chatroom key for ann user :", uuid, access_token)
        const chat_res = await fetch(`${API_URL}/api/v1/chattokens`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${access_token}`,
                'uuid':uuid,
                'prev_access_token':access_token
            }
        })
        const chat_res_status = chat_res.status
        const chat_data = await chat_res.json()
        if(chat_res_status === 200) {
            localStorage.setItem('prompt_chat_room', chat_data['chat_token'])
            setShowLoading(false)
        }else{
            if(chat_res_status === 401 && tryAgain){
                await get_new_access_token(uuid, access_token, prompt_chatroom_key, API_URL, aes_salt_key)
                const prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)
                get_chatroom_key(uuid, prompt_access_token)
                setTryAgain(false)
            }else{
                console.log("Something went wrong when getting chat token :", chat_data, chat_res_status)
            }
        }
    }


    



    // app logic for anonymous user
    useEffect(()=>{
        const initialization = async() =>{
            // console.log section
            console.log("\ndecrypted value :",
                        'prompt_uuid :', prompt_uuid, '\n',
                        'prompt_access_token :', prompt_access_token, '\n',
                        'given_chatroom_key :', given_chatroom_key, '\n')
    
            if(!prompt_uuid){
                create_ann_user()
            }else if(!prompt_chatroom_key){
                get_chatroom_key(prompt_uuid, prompt_access_token)
            }else if(given_chatroom_key !== prompt_chatroom_key){
                console.log("\n\n------------>Getting brand new access token\n\n")
                navigate(`/agent/natasha/${prompt_chatroom_key}`)
                await get_new_access_token(prompt_uuid, prompt_access_token, prompt_chatroom_key, API_URL, aes_salt_key)
                const new_prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)
                setSocket(get_socket(new_prompt_access_token))

            }else{
                await get_new_access_token(prompt_uuid, prompt_access_token, prompt_chatroom_key, API_URL, aes_salt_key)
                const new_prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)
                setSocket(get_socket(new_prompt_access_token))
            }
        }
        initialization()
    }, [])


    useEffect(()=>{
        if(socket){
            setShowLoading(false)
        }
    }, [socket])


    return (
        <>
            {showLoading?(
                <div className={classes.full_screen_center_center}>
                    <GooSpinner/>
                </div>
            ):(
                <div className={classes.full_screen_center_center}>
                    {width > 500 && <NavBar/>}
                    {showNavBarMobile && <NavBarMobile />}
                    <Chat socket={socket}/>
                </div>
            )}
        </>
    )
}

export default Main;